import { useEffect, useState } from "react";
import { getReqOpt_GET, runFetch, spURL_GET } from "../../functions/RunFetch";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ConvertToDollars } from "../../functions/ConvertToCurrency";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DailySalesObj {
  Store: string;
  Today: number;
  Yesterday: number;
  LastWeek: number;
  LastWeekTOD: number;
  LastYear: number;
  LastYearTOD: number;
  Budget: number;
  BudgetTOD: number;
}

interface TotalSalesObj {
  Today: number;
  Yesterday: number;
  LastWeek: number;
  LastWeekTOD: number;
  LastYear: number;
  LastYearTOD: number;
  Budget: number;
  BudgetTOD: number;
}

function TodaysSales() {
  const [Message, setMessage] = useState<string>("");
  const emptyTotalSales = {
    Today: 0,
    Yesterday: 0,
    LastWeek: 0,
    LastWeekTOD: 0,
    LastYear: 0,
    LastYearTOD: 0,
    Budget: 0,
    BudgetTOD: 0,
  };
  const [DailySalesTable, setDailySalesTable] = useState<DailySalesObj[]>([]);
  const [TotalSales, setTotalSales] = useState<TotalSalesObj>(emptyTotalSales);

  const [sortColumn, setSortColumn] = useState("Today");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortIcon, setSortIcon] = useState(solid("sort-down"));

  const getTodaysSales = async () => {
    const res = await runFetch(spURL_GET + "Portal_DailySalesPerformance", getReqOpt_GET());

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDailySalesTable(
          spResponse.Table.map((item: DailySalesObj) => ({
            Store: item.Store,
            Today: item.Today,
            Yesterday: item.Yesterday,
            LastWeek: item.LastWeek,
            LastWeekTOD: item.LastWeekTOD,
            LastYear: item.LastYear,
            LastYearTOD: item.LastYearTOD,
            Budget: item.Budget,
            BudgetTOD: item.BudgetTOD,
          }))
        );

        setTotalSales(
          spResponse.Table.reduce((a: DailySalesObj, b: DailySalesObj) => {
            return {
              Today: a.Today + b.Today,
              Yesterday: a.Yesterday + b.Yesterday,
              LastWeek: a.LastWeek + b.LastWeek,
              LastWeekTOD: a.LastWeekTOD + b.LastWeekTOD,
              LastYear: a.LastYear + b.LastYear,
              LastYearTOD: a.LastYearTOD + b.LastYearTOD,
              Budget: a.Budget + b.Budget,
              BudgetTOD: a.BudgetTOD + b.BudgetTOD,
            };
          }, emptyTotalSales)
        );
      } else {
        setDailySalesTable([]);
      }
    }
  };

  const handleClick_HeaderRow = (_colname: string, _sortorder: string) => {
    if (_colname === "Date") {
    } else {
      setSortColumn(_colname);

      if (_colname === sortColumn) {
        if (_sortorder === "ASC") {
          setSortOrder("DESC");
        } else {
          setSortOrder("ASC");
        }
      } else {
        setSortOrder("ASC");
      }
    }
  };

  useEffect(() => {
    getTodaysSales();
  }, []);

  useEffect(() => {
    let newArr = DailySalesTable;

    if (newArr.length > 0 && sortColumn !== "") {
      newArr = newArr.sort((a: DailySalesObj, b: DailySalesObj) => {
        switch (sortColumn) {
          case "Store":
            return (a[sortColumn] > b[sortColumn] ? 1 : -1) * (sortOrder === "ASC" ? 1 : -1);
          case "Today":
          case "Yesterday":
          case "LastWeek":
          case "LastWeekTOD":
          case "LastYear":
          case "LastYearTOD":
          case "Budget":
          case "BudgetTOD":
            return (a[sortColumn] - b[sortColumn]) * (sortOrder === "ASC" ? 1 : -1);
          default:
            return 0;
        }
      });

      setDailySalesTable(newArr);
    }
  }, [sortColumn, sortOrder, DailySalesTable, TotalSales]);

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  return (
    <div className="imagesimporttool-container no-select">
      <div className="dailysalesperformance-total-container">
        <div className="dailysalesperformance-total">
          <p>
            <strong>{ConvertToDollars(TotalSales.Today)}</strong>
          </p>
          <p>Today</p>
        </div>
        <div className="dailysalesperformance-total">
          <p>
            <strong>{ConvertToDollars(TotalSales.Yesterday)}</strong>
          </p>
          <p>Yesterday</p>
        </div>
        <div className="dailysalesperformance-total">
          <p>
            <strong>{ConvertToDollars(TotalSales.Budget)}</strong>
          </p>
          <p>Budget</p>
        </div>
        <div className="dailysalesperformance-total">
          <p>
            <strong>{ConvertToDollars(TotalSales.LastWeek)}</strong>
          </p>
          <p>LastWeek</p>
        </div>
        <div className="dailysalesperformance-total">
          <p>
            <strong>{ConvertToDollars(TotalSales.LastWeekTOD)}</strong>
          </p>
          <p>LastWeekTOD</p>
        </div>
        <div className="dailysalesperformance-total">
          <p>
            <strong>{ConvertToDollars(TotalSales.BudgetTOD)}</strong>
          </p>
          <p>BudgetTOD</p>
        </div>
        <div className="dailysalesperformance-total">
          <p>
            <strong>{ConvertToDollars(TotalSales.LastYear)}</strong>
          </p>
          <p>LastYear</p>
        </div>
        <div className="dailysalesperformance-total">
          <p>
            <strong>{ConvertToDollars(TotalSales.LastYearTOD)}</strong>
          </p>
          <p>LastYearTOD</p>
        </div>
      </div>

      <table className="itemdetails-table">
        <thead>
          <tr className="no-highlight btnHover">
            <th onClick={() => handleClick_HeaderRow("Store", sortOrder)}>
              Store
              {sortColumn === "Store" && <FontAwesomeIcon icon={sortIcon} />}
            </th>
            <th onClick={() => handleClick_HeaderRow("Today", sortOrder)}>
              Today
              {sortColumn === "Today" && <FontAwesomeIcon icon={sortIcon} />}
            </th>
            <th onClick={() => handleClick_HeaderRow("Yesterday", sortOrder)}>
              Yesterday
              {sortColumn === "Yesterday" && <FontAwesomeIcon icon={sortIcon} />}
            </th>
            <th onClick={() => handleClick_HeaderRow("LastWeek", sortOrder)}>
              LastWeek
              {sortColumn === "LastWeek" && <FontAwesomeIcon icon={sortIcon} />}
            </th>
            <th onClick={() => handleClick_HeaderRow("LastWeekTOD", sortOrder)}>
              LastWeekTOD
              {sortColumn === "LastWeekTOD" && <FontAwesomeIcon icon={sortIcon} />}
            </th>
            <th onClick={() => handleClick_HeaderRow("LastYear", sortOrder)}>
              LastYear
              {sortColumn === "LastYear" && <FontAwesomeIcon icon={sortIcon} />}
            </th>
            <th onClick={() => handleClick_HeaderRow("LastYearTOD", sortOrder)}>
              LastYearTOD
              {sortColumn === "LastYearTOD" && <FontAwesomeIcon icon={sortIcon} />}
            </th>
            <th onClick={() => handleClick_HeaderRow("Budget", sortOrder)}>
              Budget
              {sortColumn === "Budget" && <FontAwesomeIcon icon={sortIcon} />}
            </th>
            <th onClick={() => handleClick_HeaderRow("BudgetTOD", sortOrder)}>
              BudgetTOD
              {sortColumn === "BudgetTOD" && <FontAwesomeIcon icon={sortIcon} />}
            </th>
          </tr>
          <tr>
            <th>Total</th>
            <th>{ConvertToDollars(TotalSales.Today)}</th>
            <th>{ConvertToDollars(TotalSales.Yesterday)}</th>
            <th>{ConvertToDollars(TotalSales.LastWeek)}</th>
            <th>{ConvertToDollars(TotalSales.LastWeekTOD)}</th>
            <th>{ConvertToDollars(TotalSales.LastYear)}</th>
            <th>{ConvertToDollars(TotalSales.LastYearTOD)}</th>
            <th>{ConvertToDollars(TotalSales.Budget)}</th>
            <th>{ConvertToDollars(TotalSales.BudgetTOD)}</th>
          </tr>
        </thead>
        <tbody>
          {DailySalesTable.map((row, index) => (
            <tr key={index}>
              <td>{row.Store}</td>
              <td>{ConvertToDollars(row.Today)}</td>
              <td>{ConvertToDollars(row.Yesterday)}</td>
              <td>{ConvertToDollars(row.LastWeek)}</td>
              <td>{ConvertToDollars(row.LastWeekTOD)}</td>
              <td>{ConvertToDollars(row.LastYear)}</td>
              <td>{ConvertToDollars(row.LastYearTOD)}</td>
              <td>{ConvertToDollars(row.Budget)}</td>
              <td>{ConvertToDollars(row.BudgetTOD)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="error-message">{Message}</p>
    </div>
  );
}

export default TodaysSales;
